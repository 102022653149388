import React from 'react';
import './App.scss';
import Home from './pages/Home';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from './components/Layout';
import Page404 from './pages/Page404';
import routes from './routes';


function App() {
  const router = createBrowserRouter([
    {
      element: <Layout />,
      errorElement: <Page404 />,
      children: routes
    }
  ]);

  return <RouterProvider router={router} />;
}

export default App;