import React, { Suspense } from "react";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import Loader from "./Loader";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Header
          headerPosition="header--static"
          logo="symbol-dark"
          color="color-black"
        />
        <main>
          <Outlet />
        </main>
        <Footer />
      </Suspense>
    </>
  );
};

export default Layout;
