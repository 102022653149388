import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";
import { HashLink } from "react-router-hash-link";
import logoDefault from "../../assets/images/logo/logo.png";
import logoLight from "../../assets/images/logo/logo-light.png";
import logoDark from "../../assets/images/logo/logo-dark.png";
import logoSymbolDark from "../../assets/images/logo/logo-symbol-dark.png";
import logoSymbolLight from "../../assets/images/logo/logo-symbol-light.png";
import logoAllDark from "../../assets/images/logo/logo-all-dark.png";
interface HeaderProps {
  logo: string;
  color: string;
  headerPosition: string;
}
const Header: React.FC<HeaderProps> = ({ logo, color, headerPosition }) => {
  useEffect(() => {
    const elements = document.querySelectorAll(".has-droupdown > a");
    elements.forEach((element) => {
      element.addEventListener("click", function (this: HTMLElement) {
        const submenu = this.parentElement?.querySelector(".submenu");
        if (submenu) {
          submenu.classList.toggle("active");
          this.classList.toggle("open");
        }
      });
    });
  }, []);
  const menuTrigger = () => {
    const headerWrapper = document.querySelector(".header-wrapper");
    if (headerWrapper) {
      headerWrapper.classList.toggle("menu-open");
    }
  };

  const CLoseMenuTrigger = () => {
    const headerWrapper = document.querySelector(".header-wrapper");
    if (headerWrapper) {
      headerWrapper.classList.remove("menu-open");
    }
  };

  let logoUrl;
  switch (logo) {
    case "light":
      logoUrl = <img src={logoLight} alt="Trydo" />;
      break;
    case "dark":
      logoUrl = <img src={logoDark} alt="Trydo" />;
      break;
    case "symbol-dark":
      logoUrl = <img src={logoSymbolDark} alt="Trydo" />;
      break;
    case "all-dark":
      logoUrl = <img src={logoAllDark} alt="Trydo" />;
      break;
    case "symbol-light":
      logoUrl = <img src={logoSymbolLight} alt="Trydo" />;
      break;
    default:
      logoUrl = <img src={logoDefault} alt="Trydo" />;
  }

  return (
    <header className={`header-area formobile-menu ${headerPosition} ${color}`}>
      <div className="header-wrapper" id="header-wrapper">
        <div className="header-left">
          {/* <div className="logo">
            <a href="/">{logoUrl}</a>
          </div> */}
          <h3 className="m-0">Sameera Peiris</h3>
        </div>
        <div className="header-right">
          <nav className="mainmenunav d-lg-block">
            <ul className="mainmenu">
              <li className="has-droupdown">
                <Link to="/">Home</Link>
              </li>

              <li>
                <a href="/#portfolio">Work</a>
              </li>

              <li>
                <Link to="/my-cv">CV</Link>
              </li>
            </ul>
          </nav>
          <div className="header-btn">
            <HashLink
              className="rn-btn"
              to="/#contact"

              // etc...
            >
              <span>Hire Me!</span>
            </HashLink>
          </div>
          {/* Start Humberger Menu  */}
          <div className="humberger-menu d-block d-lg-none pl--20">
            <span onClick={menuTrigger} className="menutrigger text-white">
              <FiMenu />
            </span>
          </div>
          {/* End Humberger Menu  */}
          <div className="close-menu d-block d-lg-none">
            <span onClick={CLoseMenuTrigger} className="closeTrigger">
              <FiX />
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
