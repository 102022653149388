
import React from "react"
import PathConstants from "./PathConstants"

const Home = React.lazy(() => import("../pages/Home"))
const MyResume = React.lazy(() => import("../pages/MyResume"))
const PorfolioDetail = React.lazy(() => import("../pages/PorfolioDetail"))
const Contact = React.lazy(() => import("../pages/Home"))
// const portfolio = React.lazy(() => import("../pages/Home/#portfolio"))


const routes = [
    { path: PathConstants.HOME, element: <Home /> },
    { path: PathConstants.MYCV, element: <MyResume /> },
    { path: PathConstants.PORTFOLIO, element: <PorfolioDetail /> },
    { path: PathConstants.CONTACT, element: "/#contact" },

]
export default routes