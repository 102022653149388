import React from 'react'
import { Audio, MutatingDots } from 'react-loader-spinner';
const Loader = () => {
  return (
 
    <MutatingDots
    visible={true}
    height="100"
    width="100"
    color="#f9004d"
    secondaryColor="#f9004d"
    radius="12.5"
    ariaLabel="mutating-dots-loading "
    wrapperStyle={{}}
    wrapperClass=" sp-loader"
    />
  )
}

export default Loader